
import { defineComponent } from 'vue';
import Web3 from 'web3'
import axios from 'axios'
export default defineComponent({
  name: 'HelloWorld',
  data: () => ({
    address: "",
    amount: 0,
    email: '',
    claimed: false,
    fetched: false,
    philappAddress: '0x8e9a4d8f7b4c5e354ccbbdfc218f640a2cf5741d',
    tx: '',
    fetching: false
  }),
  computed: {
    isvalidAddress() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return Web3.utils.isAddress(this.address) || re.test(this.address);
    }
  },
  methods: {
    async pasteAddress() {
      const address = await navigator.clipboard.readText()
      this.address = address
    }
  },
  watch: {
    async address(newAddress, oldAddress) {
      oldAddress
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (Web3.utils.isAddress(newAddress) || re.test(this.address)) {
        try {
          this.fetching = true
          const res = await axios.get(`https://airdrop.gofmhhqp6b8ms.us-east-1.cs.amazonlightsail.com/airdrop/winner/${newAddress}/`)
          if(res.status == 200){
            this.amount = res.data.amount
            this.email = res.data.email
            this.fetched = true
            this.claimed = res.data.claimed
            this.tx = res.data.tx
            this.philappAddress = res.data.address
          } else {
            this.fetched = false
          }
          this.fetching = false
        } catch (error) {
          this.fetched = false
          this.fetching = false
        }
      } else {
        this.fetched = false
      }
    }
  }
});
